@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Inter', sans-serif;
        line-height: 1.3;
    }

    table {
    }

    th,
    td {
        @apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
    }

    thead {
        @apply bg-neutral-100;
    }

    th {
        @apply font-semibold;
    }

    a {
        @apply text-sky-500 hover:underline;
    }

    .beta-text {
        position: relative;
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 300;
        color: #ff0000;
    }

    .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: #3498db;
        width: 30px;
        height: 30px;
        animation: spin 1s ease-in-out infinite;
    }

    .spinnerButton {
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: #ffffff;
        width: 20px;
        height: 20px;
        animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}