/* src/components/Controls.css */

.controls-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.control-button {
    border: none;
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.control-button img {
    width: 24px;
    height: 24px;
}

.control-button-on {
    background-color: rgba(0, 128, 0, 0.6); /* Slight green */
}

.control-button-off {
    background-color: rgba(255, 0, 0, 0.6); /* Slight red */
}

.control-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.end-call-button {
    background-color: red;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
}

.end-call-button:hover {
    background-color: darkred;
}
